import React, { createContext, useState, useEffect, useContext } from "react";
import { initializeSocket, getSocket } from "./sockSingleton";
import { AuthContext } from "./AuthContext"; // Adjust the import path as necessary
import axiosInstance from "./axiosConfig";
import { PropertyClientContext } from "./PropertyClientContext";
import {ChatContext} from "./ChatContext";
// Message type definition
interface Message {
  id: number;
  content: string;
  type: string;
  timestamp: string;
  property_id: number;
  customer_phone: string;
  userId: number;
  error?: boolean;
}

// Define the shape of your context state
interface MessagesContextState {
  // messages: { [key: number]: Message[] };
  messages: { [property_id: number]: { [customer_phone: number]: Message[] } };
  loadMessages: (customer_phone: number, property_id: number) => void;
  // updateMessages: (userId: number, newMessages: Message[]) => void;
  updateMessages: (propertyId: number, newMessagesForProperty: { [customerPhone: string]: Message[] }) => void;

  latestMessageInfo: { userId: number; message: Message } | null; // This property should be included
  isChatPageOpen: boolean;
  setIsChatPageOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MessagesProviderProps {
  children: React.ReactNode;
}

// Initialize the context with a default state
export const MessagesContext = createContext<MessagesContextState | null>(null);

export const MessagesProvider: React.FC<MessagesProviderProps> = ({
  children,
}) => {
  // const [messages, setMessages] = useState<{ [key: number]: Message[] }>({});
  const [messages, setMessages] = useState<{ [propertyId: string]: { [customerPhone: string]: Message[] } }>({});


  // const socket = io(process.env.REACT_APP_WEBSOCKETS_URL+'');
  const [latestMessageInfo, setLatestMessageInfo] = useState<{
    userId: number;
    message: Message;
  } | null>(null);
  const [isChatPageOpen, setIsChatPageOpen] = useState(false);
  const [loadedUserIds, setLoadedUserIds] = useState<Set<number>>(new Set()); // State to keep track of loaded userIds
  const propertyClientContext = useContext(PropertyClientContext);
  const { propertyClients = [], updatePropertyList, currentProperty, setCurrentProperty } = propertyClientContext || {}; // Using optional chaining or a default object
  const chatContext = useContext(ChatContext);
  const { chatters = [], updateChatters, isLoading } = chatContext || {}; // Using optional chaining or a default object


  // Use AuthContext to access the accessToken
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    // console.log('chatPage is closed');

    // This function receives the messages from the socket
    const handleMessageReceived = (newMessage: Message) => {
      // console.log(newMessage);
      setMessages((prevMessages) => {
        const property_id = newMessage.property_id;
        const property_idInt = parseInt(String(property_id));
        newMessage.property_id = property_idInt;
        const customer_phone = newMessage.customer_phone.replace(/\D/g, '');

        console.log(prevMessages);
        console.log(property_idInt);
        // Check if propertyId and customerPhone already exist in the state
        const propertyMessages = prevMessages[property_idInt] || {};
        const customerMessages = propertyMessages[customer_phone] || [];


        // Check for a message with id: 0 and the same content
        const messageIndex = customerMessages.findIndex(
            (m) => m.id === 0 && m.content === newMessage.content
        );

        // Check if message is already in object
        // Check for a message with id: with the same id and the same content
        const messageIndex2 = customerMessages.findIndex(
            (m) => m.id === newMessage.id && m.content === newMessage.content
        );


        if (messageIndex > -1 || messageIndex2 > -1) {
          // Update the existing message
          console.log('update existing message');
          customerMessages[messageIndex] = {
            ...customerMessages[messageIndex],
            ...newMessage,
          };
        } else {
          // Add the new message
          console.log('adding new message');
          customerMessages.push(newMessage);
        }
        // setLatestMessageInfo({ userId: newMessage.userId, message: newMessage });


        return {
          ...prevMessages,
          [property_id]: {
            ...propertyMessages,
            [customer_phone]: customerMessages,
          },
        };
      });

      console.log("newMessage coming in handleMessageReceived sockets");
      console.log("heeere");
      console.log(newMessage);
      setLatestMessageInfo({ userId: newMessage.userId, message: newMessage });
      // console.log(latestMessageInfo);
    };




    if (accessToken) {
      // console.log(accessToken);
      initializeSocket(accessToken);
      const socket = getSocket();
      console.log("newMessage coming in sockets");

      socket.on("newMessage", handleMessageReceived);

      // Clean up the event listener when the component unmounts
      return () => {
        socket.off("newMessage", handleMessageReceived);
        socket.disconnect();
      };
    }
  }, [accessToken]); // Dependency array includes accessToken to re-run when it changes


  // Function updates the newest message on main page
  useEffect(() => {

    if (latestMessageInfo && updateChatters && latestMessageInfo.message) {
      const propertyId = latestMessageInfo.message.property_id; // Assuming this is available
      // const property_idInt = parseInt(String(propertyId));
      // Check if chatters for the current property exist
      if (chatters[propertyId]) {
        // Find the chatter object that matches the userId of the latest message
        const chatterIndex = chatters[propertyId].findIndex(
            (chatter) => chatter.userId === latestMessageInfo.userId
        );
        console.log(chatterIndex);
        if (chatterIndex !== -1) {

          // Clone the current chatters array for immutability
          const updatedChatters = [...chatters[propertyId]];

          // Update the last message of the relevant chatter
          if (updatedChatters[chatterIndex].lastMessage !== latestMessageInfo.message.content) {
            updatedChatters[chatterIndex] = {
              ...updatedChatters[chatterIndex],
              lastMessage: latestMessageInfo.message.content.slice(0, 80)
            };
            // Call updateChatters function to set the state with the updated chatters array
            updateChatters({
              ...chatters,
              [propertyId]: updatedChatters
            });

          }
        }
      }
    }

  }, [latestMessageInfo]);







  const updateMessages = (propertyId: number, newMessagesForProperty: { [customerPhone: string]: Message[] }) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [propertyId]: newMessagesForProperty,
    }));
  };


  // Function to load messages for a specific chatter
  // const loadMessages = async (customer_phone: number) => {
  //   // Check if the userId is not in the loadedUserIds set to determine if the messages for this user have already been loaded
  //   if (!loadedUserIds.has(customer_phone) && currentProperty) {
  //     try {
  //       console.log("getting messages");
  //       // Make an API call to fetch messages for the specified userId
  //       const response = await axiosInstance.post(
  //         process.env.REACT_APP_BACKEND_DOMAIN_URL +
  //           "/api/get-one-phone-com-log",
  //         { customer_phone: customer_phone, property_id: currentProperty.property_id }
  //       );
  //       console.log(response);
  //
  //       // Sort the messages by their timestamp
  //       const sortedMessages = response.data.sort(
  //         (a: Message, b: Message) =>
  //           new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  //       );
  //
  //       // Update the messages state with the sorted messages for the specified userId
  //       setMessages((prevMessages) => ({
  //         ...prevMessages,
  //         [customer_phone]: sortedMessages,
  //       }));
  //
  //       // Add the userId to the loadedUserIds set to indicate that messages for this user have been loaded
  //       setLoadedUserIds((prevLoadedUserIds) =>
  //         new Set(prevLoadedUserIds).add(customer_phone)
  //       );
  //     } catch (error) {
  //       // Log an error message if the API call fails
  //       console.error("Error fetching messages:", error);
  //     }
  //   }
  // };

  const loadMessages = async (customer_phone: number, property_id: number) => {
    // if (currentProperty && (!messages[currentProperty.property_id] || !messages[currentProperty.property_id][customer_phone])) {
      try {
        console.log("getting messages");
        const response = await axiosInstance.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN_URL}/api/get-one-phone-com-log`,
            { customer_phone, property_id: property_id }
        );
        // console.log(response);


        const sortedMessages = response.data.sort(
            (a: Message, b: Message) =>
                new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );


        setMessages(prevMessages => ({
          ...prevMessages,
          [property_id]: {
            ...prevMessages[property_id],
            [customer_phone]: sortedMessages,
          },
        }));
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    // }
  };


  return (
    <MessagesContext.Provider
      value={{
        messages,
        loadMessages,
        updateMessages,
        latestMessageInfo,
        isChatPageOpen,
        setIsChatPageOpen,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
