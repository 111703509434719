// axiosConfig.ts
import axios from "axios";
import { getNewAccessToken } from "./authService"; // A service function to get a new access token
import { setToken, getToken } from "./tokenService";

// Request Interceptor to set the token in the Authorization header
axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    // console.log("interceptor setting token to header");
    // console.log(token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor for handling 401 errors and token refresh
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the response is a 401 and the request hasn't been retried yet
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await getNewAccessToken(); // Function to get a new access token
        setToken(newAccessToken); // Update the stored token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`; // Update the authorization header
        return axios(originalRequest); // Retry the original request with the new token
      } catch (refreshError) {
        return Promise.reject(refreshError); // If token refresh fails, reject the promise
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
