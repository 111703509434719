import React, {
  lazy,
  Suspense,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
// import axios from 'axios';
import axiosInstance from "./axiosConfig";
import {
  Box,
  BoxProps,
  VStack,
  Text,
  Button,
  Flex,
  HStack,
  Spacer,
  IconButton,
  Avatar,
  Textarea,
} from "@chakra-ui/react";
import { ChevronLeftIcon, AddIcon } from "@chakra-ui/icons";
import { FaPaperPlane } from "react-icons/fa";
import { MessagesContext } from "./MessagesContext"; // Ensure this is correctly imported
import { ChatGPTContext } from "./ChatGPTContext";
import { dateFormatter } from "./utils/timeParsers";
import "./styles.css";
import { AuthContext } from "./AuthContext";
import ChatGPTSuggestionBox from "./ChatGPTSuggestionBox";
const ChatSearchComponent = lazy(() => import("./ChatSearchComponent"));
const Highlight = lazy(() => import("./HighlighterComponent"));
const FileUploadComponent = lazy(() => import("./FileUploadComponent"));
const ContactModal = lazy(() => import("./ContactModal"));
const CallModal = lazy(() => import("./CallModal"));
const ChatMessageActionButtonsComponent = lazy(
  () => import("./ChatMessageActionButtonsComponent")
);

interface MessageArrowProps extends BoxProps {
  direction: "sent" | "received";
}

const MessageArrow: React.FC<MessageArrowProps> = ({ direction, ...rest }) => {
  const arrowStyles: React.CSSProperties = {
    borderWidth: "5px",
    borderStyle: "solid",
    marginTop: "-5px", // Adjust this value to vertically center the arrow
  };

  if (direction === "sent") {
    arrowStyles.borderColor = "transparent transparent transparent #dcf8c6"; // Match the background color of sent messages
  } else if (direction === "received") {
    arrowStyles.borderColor = "transparent #ffffff transparent transparent"; // Match the background color of received messages
  }

  return <Box as="div" style={arrowStyles} {...rest} />;
};

interface Chatter {
  userId: number;
  name: string;
  avatar: string;
  lastMessage: string;
  date: string;
  property_id: number;
  unit_number:string;
  phone_number_formatted:string;
  full_name:string;
  customer_type:string;
}

// interface Message {
//   id: number;
//   content: string;
//   type: string;
//   timestamp: string;
//   property_id: string;
//   customer_phone: string;
//   userId: number;
//   error?: boolean;
// }
interface Message {
  id: number;
  content: string;
  type: string;
  timestamp: string;
  property_id: number;
  customer_phone: string;
  userId: number;
  error?: boolean;
}

interface ChatPageProps {
  currentChatter: Chatter | null;
  onBack: () => void; // Add the onBack prop
}

export const ChatPage: React.FC<ChatPageProps> = ({
  currentChatter,
  onBack,
}) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const contentRef = useRef<HTMLDivElement>(null);
  const { customer_phone } = useParams<{ customer_phone: string }>(); // Assuming the parameter is named 'id' in the route
  const customer_phoneInt = parseInt(customer_phone || "0");
  const { property_id } = useParams<{ property_id: string }>();
  const property_idInt = parseInt(property_id || "0");
  const chatGPTContext = useContext(ChatGPTContext);
  const { accessToken } = useContext(AuthContext);
  const {
    showMenu,
    setShowMenu,
    handleModeChange,
    response,
    inputText,
    setInputText,
  } = chatGPTContext || {};

  /* Update the input field whenever the response changes
  useEffect(() => {
    // Trim the response to remove leading and trailing spaces
    // let trimmedResponse = response.trim();

    setInputText(response);
    setNewMessage(response); // This updates the newMessage state
    adjustTextareaHeight();
    // adjustTextareaHeight();
  }, [response]);
   */

  // Function to handle input change
  // Function to handle input change
  // const handleInputChange = (e:string) => {
  //     setInputText(e.target.value);
  // };
  if (isNaN(customer_phoneInt)) {
    // SET SOME LOGIC HERE TO GO BACK TO MAINPAGE IF THERE IS NO CHATTER ID
    // Handle the error case, e.g., redirect or show an error
    //   console.error("Invalid or missing 'id' in URL parameters.");
    //   navigate('/error-page'); // Redirect to an error page or home page
  }

  const messagesContext = useContext(MessagesContext);
  // Assuming messagesContext can be null, provide a default value
  const {
    messages = {},
    loadMessages,
    updateMessages,
    isChatPageOpen,
    setIsChatPageOpen,
  } = messagesContext || {};

  // const currentMessages =
  //     customer_phoneInt !== undefined
  //     ? messages[customer_phoneInt]
  //     : [];
  // const currentMessages = (property_idInt !== undefined && customer_phoneInt !== undefined)
  //     ? (messages[property_idInt]?.[customer_phoneInt] || [])
  //     : [];
  const currentMessages =
    property_idInt !== undefined && customer_phoneInt !== undefined
      ? messages[property_idInt]?.[customer_phoneInt] || []
      : [];

  // console.log(messages);

  // const isIphone = /iPhone/.test(navigator.userAgent);

  // const adjustLayoutForIphone = () => {
  //     if (/iPhone/.test(navigator.userAgent)) {
  //         // Adjust the top section
  //         const topElement = document.querySelector('.top-header') as HTMLElement;
  //         if (topElement) {
  //             topElement.style.paddingTop = `calc(env(safe-area-inset-top) + 40px)`;
  //         }
  //         // Adjust the layout for iPhone
  //         const footerElement = document.querySelector('.footer') as HTMLElement;
  //         if (footerElement) {
  //             footerElement.style.paddingBottom = 'calc(env(safe-area-inset-bottom) + 20px)';
  //         }
  //
  //     }
  // };

  // window.addEventListener('resize', adjustLayoutForIphone);
  // window.addEventListener('load', adjustLayoutForIphone);

  // const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const bottomMessageRef = useRef<HTMLDivElement>(null);
  const [attachments, setAttachments] = useState<any>([]);

  useEffect(() => {
    // console.log('loadMessages');
    console.log(customer_phoneInt);
    if (
      accessToken &&
      customer_phoneInt != 0 &&
      customer_phoneInt &&
      loadMessages &&
      property_idInt
    ) {
      try {
        if (!isNaN(property_idInt) && !isNaN(customer_phoneInt)) {
          // console.log(property_idInt);
          console.log("loadMessages");
          loadMessages(customer_phoneInt, property_idInt);
        }
      } catch (error) {
        console.error("Error loading messages:", error);
      }
    }
  }, [accessToken, customer_phoneInt]);

  const handleSendMessage = async () => {
    if (newMessage.trim() && property_idInt && customer_phoneInt) {
      const newMsg = {
        id: 0, // Temporarily assign an ID (ideally this should come from the server response)
        content: newMessage,
        type: "sent",
        timestamp: new Date().toISOString(),
        userId: customer_phoneInt,
        property_id: property_idInt,
        customer_phone: customer_phoneInt.toString(),
        // ... any other fields
      };

      // Attempt to update the messages state
      // Prepare the updated messages array
      let updatedMessages = [
        ...(messages[property_idInt]?.[customer_phoneInt] || []),
        newMsg,
      ];

      try {
        // Update the local state for instant UI response
        if (updateMessages) {
          let updatedPropertyMessages = {
            ...messages[property_idInt],
            [customer_phoneInt]: updatedMessages,
          };

          updateMessages(property_idInt, updatedPropertyMessages);

          // updateMessages(property_idInt, {
          //   ...messages[property_idInt],
          //   [customer_phoneInt]: updatedMessages
          // });

          setNewMessage("");
          setInputText("");
          const payload = {
            ...newMsg, // spreading the object to include userId
          };
          // Send the message to the server
          const response = await axiosInstance.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN_URL}/api/send-text`,
            payload
          );

          if (response.data !== "success") {
            throw new Error("Response was not successful");
          }

          // Handle server response (like updating message ID with server-generated ID)
        }
      } catch (error) {
        console.error("Error sending message:", error);

        // Indicate an error with the specific message in the state
        if (updateMessages) {
          updatedMessages = updatedMessages.map((msg) =>
            msg.id === newMsg.id ? { ...msg, error: true } : msg
          );

          updateMessages(property_idInt, {
            ...messages[property_idInt],
            [customer_phoneInt]: updatedMessages,
          });
        }
      }
    }
  };

  useEffect(() => {
    // Check if the bottom message reference exists and if there are new messages
    // console.log('scrolling down');
    // console.log(bottomMessageRef);
    // if (bottomMessageRef.current && currentMessages.length > 0) {
    //   bottomMessageRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    const timer = setTimeout(() => {
      if (bottomMessageRef.current) {
        bottomMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, [customer_phoneInt, currentMessages, customer_phoneInt, loadMessages]); // Dependency on currentMessages to trigger scroll when messages update

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const buttomFooterRef = useRef<HTMLDivElement>(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewMessage(e.target.value); // This updates the newMessage state
    setInputText(e.target.value);
    adjustTextareaHeight();
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [newMessage]); // Adjust height when newMessage changes

  useEffect(() => {
    if (messagesContext) {
      // console.log("useeffect in ChatPage");
      messagesContext.setIsChatPageOpen(true);
      return () => {
        if (messagesContext) {
          messagesContext.setIsChatPageOpen(false);
        }
      };
    }
  }, [messagesContext]);


  const getInitials = (name: string): string => {
    const matches = name.match(/\b\w/g) || []; // This will be an array of matches
    const initials = ((matches.shift() || '') + (matches.pop() || '')).toUpperCase();
    return initials;
  };


  return (
    <VStack spacing={0} h="100vh" w="100%" bg="gray.100">
      {/* Top Header Section */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="gray.100"
        sx={{
          WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS
        }}
      >
        <Button
          size="sm"
          variant="ghost"
          onClick={() => {
            onBack();
            navigate(-1);
          }}
        >
          <ChevronLeftIcon boxSize={8} />
        </Button>
        {currentChatter && (
          <Suspense fallback={<></>}>
            {/*<ContactModal*/}
            {/*  avatar={currentChatter.avatar}*/}
            {/*  name={currentChatter.name}*/}
            {/*/>*/}
            <Box
                width="40px"
                height="40px"
                borderRadius="full"
                bg="gray.200"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
              <Text fontSize="md" fontWeight="bold">
                {currentChatter.unit_number || getInitials(currentChatter.full_name)}
              </Text>
            </Box>
            <VStack align="start" spacing={1}>
              <Text fontWeight="bold">{currentChatter.name+" - "+currentChatter.customer_type}</Text>
            </VStack>
          </Suspense>
        )}
        <Spacer />
        {/* You can add more icons or components here */}
        <Suspense fallback={<></>}>
          <ChatSearchComponent
            searchTerm={searchQuery}
            setSearchTerm={setSearchQuery}
            messages={currentMessages}
            contentRef={contentRef}
          />
        </Suspense>
        {currentChatter && (
          <Suspense fallback={<></>}>
            <CallModal phone={""} />
          </Suspense>
        )}
      </HStack>

      {/* Middle Message Section */}
      <Flex
        flex="1"
        w="full"
        px={2}
        overflowY="auto"
        direction="column"
        bg="#e5ddd5"
        ref={contentRef}
      >
        {/*{messages && messages[customer_phoneInt]?.map((message, index) => (*/}
        {messages[property_idInt] &&
          messages[property_idInt][customer_phoneInt] &&
          messages[property_idInt][customer_phoneInt]?.map((message, index) => (
            <Box
              key={message.id}
              ref={
                index === messages[property_idInt][customer_phoneInt].length - 1
                  ? bottomMessageRef
                  : null
              }
              my={2}
              p={3}
              maxWidth="60%"
              alignSelf={message.type === "sent" ? "flex-end" : "flex-start"}
              bg={message.type === "sent" ? "#dcf8c6" : "#ffffff"}
              borderRadius="10px"
              borderColor={"#dcf8c6 transparent transparent transparent"}
            >
              <Suspense fallback={<></>}>
                <Highlight text={message.content} searchTerm={searchQuery} />
              </Suspense>
              <Text mt={1} fontSize="xs" color="gray" textAlign="right">
                {dateFormatter(message.timestamp)}
              </Text>
              {message.error && (
                <Text color="red.500" fontSize="sm">
                  Failed to send
                </Text>
              )}
            </Box>
          ))}
      </Flex>

      {/* Bottom Footer Section */}
      <HStack
        ref={buttomFooterRef}
        position="sticky"
        bottom="env(safe-area-inset-bottom)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        spacing={2}
        py={2}
        px={6}
        bg="gray.100"
        boxShadow="sm"
      >
        {/*
        <IconButton
          icon={<AddIcon />}
          aria-label="Add"
          variant="ghost"
          size="sm"
          onClick={() => setShowMenu(!showMenu)} // Toggle menu visibility
        />
        {showMenu && (
          <VStack>
            <Button onClick={() => handleModeChange("nice")}>Nice</Button>
            <Button onClick={() => handleModeChange("fun")}>Fun</Button>
            <Button onClick={() => handleModeChange("professional")}>
              Professional
            </Button>
            <Button onClick={() => handleModeChange("professional_spanish")}>Professional in Spanish</Button>
          </VStack>
        )}
        */}
        <Suspense fallback={<></>}>
          <ChatMessageActionButtonsComponent
            newMessage={newMessage}
            footerRef={buttomFooterRef}
            setNewMessage={setNewMessage}
            inputText={inputText}
            setInputText={setInputText}
            handleModeChange={handleModeChange}
            gptResponse={response}
          />
        </Suspense>
        <Textarea
          size="sm"
          resize="none"
          minH="unset"
          maxH={"100px"}
          ref={textareaRef}
          value={inputText}
          sx={{
            ".message-content img": {
              WebkitUserDrag: "none",
              userDrag: "none",
            },
          }}
          variant="filled"
          flex="1"
          borderRadius="md"
          placeholder="Type your message..."
          bg="white"
          onChange={handleChange} // Use handleChange here
          // onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault(); // Prevents the default action of the enter key
              handleSendMessage();
            }
          }}
        />
        <IconButton
          icon={<FaPaperPlane />}
          aria-label={"Send button"}
          onClick={(e) => handleSendMessage()}
        />
        <Suspense fallback={<></>}>
          <FileUploadComponent
            docs={attachments}
            setDocs={setAttachments}
            maxFileSize={10}
            maxAllowedFiles={3}
          />
        </Suspense>
      </HStack>
    </VStack>
  );
};

export default ChatPage;
