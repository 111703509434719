import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import MainFooter from "./MainFooter";
import axiosInstance from "./axiosConfig";

import { ChevronLeftIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";

export const SettingsPage = () => {
  const navigate = useNavigate();
  const { property_id } = useParams<{ property_id: string }>();

  async function handleLogout(): Promise<void> {
    try {
      await axiosInstance.get(
        process.env.REACT_APP_BACKEND_DOMAIN_URL + "/auth/logout"
      );

      window.location.replace(process.env.REACT_APP_LOGIN_URL + "/logout"); //Redirect URL
    } catch (e) {
      console.log("There was a problem");
    }
  }

  return (
    <VStack alignItems={"left"} spacing={0} h="100vh" w="100%" bg="gray.100">
      {/* Header */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="gray.100"
      >
        <Button onClick={(e) => navigate("/")}>
          <ChevronLeftIcon boxSize={8} color="gray.500" />
        </Button>
        <Heading size="md">Settings</Heading>
        <Spacer />
        <Button p={2} onClick={(e) => navigate("/help")}>
          <QuestionOutlineIcon boxSize={6} />
        </Button>
      </HStack>
      <Box alignItems={"left"} p={2} h="80vh">
        <Button
          colorScheme={"blue"}
          marginTop={"10px"}
          marginBottom={"10px"}
          onClick={handleLogout}
        >
          Logout
        </Button>
        <Text>Change Profile Picture</Text>
        <Flex
          gap={2}
          marginTop={"10px"}
          marginBottom={"10px"}
          alignItems={"center"}
        >
          <Avatar size={"lg"} />
          <Input type={"file"} />
        </Flex>
        <Flex gap={2} marginTop={"10px"} marginBottom={"10px"}>
          <Text>Enable Notifications</Text>
          <Switch size={"lg"} />
        </Flex>
        <Box marginTop={"10px"} marginBottom={"10px"}>
          <Text>Chat Background Color</Text>
          <Select bg={"white"}>
            <option>Light</option>
            <option>Dark</option>
          </Select>
        </Box>
      </Box>
      <MainFooter index={3} />
    </VStack>
  );
};

export default SettingsPage;
