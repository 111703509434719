import React from "react";
import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import MainFooter from "./MainFooter";

export const HelpPage = () => {
  return (
    <Box h={"100vh"} w="100%">
      {/* Header */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="gray.100"
      >
        <Heading size="md">Help</Heading>
      </HStack>
      <Box alignItems={"left"} p={2} h="80vh">
        <Text>Insert helpful text here</Text>
      </Box>
      <MainFooter index={-1} />
    </Box>
  );
};

export default HelpPage;
