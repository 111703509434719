import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { PropertyClientContext } from "./PropertyClientContext";
import {AuthContext} from "./AuthContext";
import {MessagesContext} from "./MessagesContext";
import {ChatContext} from "./ChatContext"; // Ensure this is correctly imported


interface PropertyClient {
  property_id: number;
  property_name: string;
  phoneNumber: string;
  client_id: number;
}

export const PhoneNumberSelectionPage = () => {
  const navigate = useNavigate();
  const propertyClientContext = useContext(PropertyClientContext);
  const { propertyClients = [], updatePropertyList, isLoading, currentProperty, setCurrentProperty } = propertyClientContext || {}; // Using optional chaining or a default object

  function setNumber(property_id: number) {
    console.log(property_id);
    // console.log(propertyClients);
    // const selectedProperty = propertyClients.find(client => client.property_id === property_id);
    // if (setCurrentProperty && selectedProperty) {
    //   setCurrentProperty(selectedProperty);
      // console.log(selectedProperty);
      // console.log(selectedProperty);
      navigate("/mainpage/"+property_id);
    // }

  }

  return (
    <VStack spacing={0} h="100vh" w="100%" bg="gray.100">
      {/* Header */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="gray.100"
      >
        <Heading size="md">Available Numbers</Heading>
      </HStack>
      <Text
        fontSize="sm"
        fontWeight="bold"
        alignSelf="center"
        color="gray.500"
        marginBottom={"10px"}
        marginTop={"10px"}
      >
        Select Number
      </Text>
      {/* Numbers */}
      <VStack w="100%" flex="1" overflowY="auto" spacing={1} my={1}>
        <Box as={"button"} w="100%">
          <HStack
            w="100%"
            p={4}
            spacing={4}
            borderBottom="1px"
            borderColor="gray.200"
            bg="white"
            _hover={{ bg: "gray.100" }} // Add a darker background on hover
          >
            <VStack align="start" spacing={1}>
              <Text fontSize={"lg"} fontWeight="bold">
                All Properties
              </Text>
              <Text>All Numbers</Text>
            </VStack>
            <Spacer />
            <Icon as={ChevronRightIcon} w={5} h={5} color="gray.500" />
          </HStack>
        </Box>
        {propertyClients.map((PropertyClient, index) => (
          <Box
            as={"button"}
            key={PropertyClient.phoneNumber}
            onClick={(e) => setNumber(PropertyClient.property_id)}
            w="100%"
          >
            <HStack
              w="100%"
              p={4}
              spacing={4}
              borderBottom="1px"
              borderColor="gray.200"
              bg="white"
              _hover={{ bg: "gray.100" }} // Add a darker background on hover
            >
              <VStack align="start" spacing={1}>
                <Text fontSize={"lg"} fontWeight="bold">
                  {PropertyClient.phoneNumber}
                </Text>
                <Text>{PropertyClient.property_name}</Text>
              </VStack>
              <Spacer />
              <Icon as={ChevronRightIcon} w={5} h={5} color="gray.500" />
            </HStack>
          </Box>
        ))}
      </VStack>
    </VStack>
  );
};

export default PhoneNumberSelectionPage;
