import React, { useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChatProvider } from "./ChatContext"; // Import the ChatProvider
import { MessagesProvider } from "./MessagesContext";
import { ChatGPTProvider } from "./ChatGPTContext";
import { PropertyClientProvider } from "./PropertyClientContext";
import { AuthProvider } from "./AuthContext";
import MainPage from "./MainPage";
import ChatPage from "./ChatPage";
import NewChatPage from "./NewChatPage";
import SettingsPage from "./SettingsPage";
import CallsPage from "./CallPage";
import HelpPage from "./HelpPage";
import PhoneNumberSelectionPage from "./PhoneNumberSelectionPage";
import ContactsPage from "./ContactsPage";

// Define the User type
interface Chatter {
  userId: number;
  name: string;
  avatar: string;
  lastMessage: string;
  date: string;
  property_id: number;
  unit_number:string;
  phone_number_formatted:string;
  full_name:string;
  customer_type:string;
}

function App() {
  const [currentChatter, setCurrentChatter] = useState<Chatter | null>(null);

  const startChat = (chatter: Chatter) => {
    setCurrentChatter(chatter);
  };

  const endChat = () => {
    setCurrentChatter(null);
  };

  return (
    <Router>
      <AuthProvider>
        <PropertyClientProvider>
          <ChatGPTProvider>
            <ChakraProvider>
              <ChatProvider>
                <MessagesProvider>
                  <div className="App">
                    <Routes>
                      <Route path="/" element={<PhoneNumberSelectionPage />} />
                      <Route
                        path="/mainpage/:property_id"
                        element={
                          <MainPage
                            onStartChat={startChat}
                            currentChatter={currentChatter}
                            onEndChat={endChat}
                          />
                        }
                      />
                      <Route
                        path="/mainpage/:property_id/new-chat/:customer_phone?"
                        element={<NewChatPage onBack={endChat} />}
                      />

                      <Route
                        path="/mainpage/:property_id/chat/:customer_phone"
                        element={
                          <ChatPage
                            currentChatter={currentChatter}
                            onBack={endChat} // Pass the onBack function here
                          />
                        }
                      />
                      <Route
                        path="/settings/:property_id"
                        element={<SettingsPage />}
                      />
                      <Route
                        path="/calls/:property_id"
                        element={<CallsPage />}
                      />
                      <Route path="/help" element={<HelpPage />} />
                      <Route
                        path="/contacts/:property_id"
                        element={<ContactsPage />}
                      />
                    </Routes>
                  </div>
                </MessagesProvider>
              </ChatProvider>
            </ChakraProvider>
          </ChatGPTProvider>
        </PropertyClientProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
