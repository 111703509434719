import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import axios from 'axios';
import axiosInstance from "./axiosConfig";
import {
  Box,
  VStack,
  Text,
  Button,
  Flex,
  HStack,
  Spacer,
  Icon,
  Input,
  IconButton,
  Avatar,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  AttachmentIcon,
  AtSignIcon,
  PhoneIcon,
  AddIcon,
  SearchIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { MessagesContext } from "./MessagesContext"; // Ensure this is correctly imported
import { ChatGPTContext } from "./ChatGPTContext";
import { PropertyClientContext } from "./PropertyClientContext";
interface Message {
  id: number;
  content: string;
  type: string;
  timestamp: string;
  property_id: number;
  customer_phone: string;
  userId: number;
  error?: boolean;
}

interface ChatPageProps {
  userId: number;
  name: string;
  avatar: string;
  onBack: () => void;
}

interface NewChatPageProps {
  onBack: () => void;
}

export const NewChatPage: React.FC<NewChatPageProps> = ({ onBack }) => {
  const navigate = useNavigate();

  const { customer_phone } = useParams<{ customer_phone: string }>(); // Assuming the parameter is named 'id' in the route
  const customer_phoneInt = parseInt(customer_phone || "0");
  const { property_id } = useParams<{ property_id: string }>();
  const property_idInt = parseInt(property_id || "0");
  // const [customer_phoneState, setCustomerPhoneState] = useState<number>(0);
  const bottomMessageRef = useRef<HTMLDivElement>(null);
  const [recipient, setRecipient] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const messagesContext = useContext(MessagesContext);
  // Assuming messagesContext can be null, provide a default value
  const { messages = {}, loadMessages, updateMessages } = messagesContext || {};
  // const currentMessages = userId !== undefined ? messages[userId] || [] : [];
  const chatGPTContext = useContext(ChatGPTContext);
  const [newMessage, setNewMessage] = useState("");
  const {
    showMenu,
    setShowMenu,
    handleModeChange,
    response,
    inputText,
    setInputText,
  } = chatGPTContext || {};

  useEffect(() => {
    // Update userId when phone parameter changes
    // const intCustomer_phone = parseInt(customer_phoneParam || "0", 10);
    if(customer_phoneInt && customer_phoneInt != 0){
      setRecipient(customer_phoneInt);
    }
  }, [customer_phone]);

  // Update the input field whenever the response changes
  // useEffect(() => {
  //   setInputText(response);
  // }, [response]);

    useEffect(() => {
    // Trim the response to remove leading and trailing spaces
    // let trimmedResponse = response.trim();

    setInputText(response);
    setNewMessage(response); // This updates the newMessage state
    adjustTextareaHeight();
    // adjustTextareaHeight();
  }, [response]);





  const handleSendMessage = async () => {
    if (recipient === 0) {
      // Display a notification to the user about the empty recipient field
      alert("Please enter the recipient's phone number."); // You can replace this with a more sophisticated notification mechanism
      return; // Prevent further execution of the function
    }
    console.log(recipient);
    // let customer_phone = parseInt(recipient.replace(/\D/g, ""), 10);
    // let cleanedRecipient = recipient.replace(/\D/g, "");
    // let customer_phoneFromInputInt = parseInt(cleanedRecipient, 10);
    console.log(recipient);
    // setCustomerPhoneState(customer_phone);
    if (recipient && recipient != 0 && loadMessages) {
      try {
        loadMessages(recipient,property_idInt);
      } catch (error) {
        console.error("Error loading messages:", error);
      }
    }



    if (newMessage.trim() && recipient != 0 && property_idInt) {
      const newMsg = {
        id: 0, // Temporarily assign an ID (ideally this should come from the server response)
        content: newMessage,
        type: "sent",
        timestamp: new Date().toISOString(),
        userId: recipient,
        property_id: property_idInt,
        customer_phone: recipient.toString(),
        // ... any other fields
      };

      // let updatedMessages = [...updatedCurrentMessages, newMsg]; // Local variable to track updated messages
      let updatedMessages = [...(messages[property_idInt]?.[recipient] || []), newMsg];

      try {
        // Update local state first for instant UI response
        if (updateMessages) {
          let updatedPropertyMessages = { ...messages[property_idInt], [recipient]: updatedMessages };

          updateMessages(property_idInt, updatedPropertyMessages);

          // updateMessages(userId, updatedMessages);
          setNewMessage("");
          setInputText("");

          const payload = {
            ...newMsg, // spreading the object to include userId
          };

          // Send the message to the server
          const response = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_DOMAIN_URL + "/api/send-text",
            payload
          );
          if (response.data !== "success") {
            // console.log('Response was not successful');
            throw new Error("Response was not successful");
          }

          // Handle response if necessary (like updating message ID with server-generated ID)
        }
      } catch (error) {
        console.error("Error sending message:", error);

        // Indicate an error with the specific message in the state
        if (updateMessages) {
          updatedMessages = updatedMessages.map((msg) =>
              msg.id === newMsg.id ? { ...msg, error: true } : msg
          );

          updateMessages(property_idInt, {
            ...messages[property_idInt],
            [recipient]: updatedMessages
          });
        }
      }
    }
  };

  useEffect(() => {
    // Check if the bottom message reference exists and if there are new messages
    // console.log('scrolling down');
    // console.log(bottomMessageRef);
    // if (bottomMessageRef.current && currentMessages.length > 0) {
    //   bottomMessageRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    const timer = setTimeout(() => {
      if (bottomMessageRef.current) {
        bottomMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, [customer_phoneInt, customer_phoneInt, loadMessages]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewMessage(e.target.value); // This updates the newMessage state
    setInputText(e.target.value);
    adjustTextareaHeight();
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [newMessage]); // Adjust height when newMessage changes

  return (
    <VStack spacing={0} h="100vh" w="100%" bg="gray.100">
      {/* Top Header Section */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="white"
      >
        <Button
          variant="ghost"
          onClick={() => {
            onBack();
            navigate(-1);
          }}
        >
          <ChevronLeftIcon boxSize={6} />
        </Button>
        <Input
          flex="1"
          ml={2}
          focusBorderColor="blue.500"
          placeholder="To:"
          value={recipient.toString()}
          onChange={(e) => setRecipient(parseInt(e.target.value.replace(/\D/g, ""), 10) || 0)}
        />
        {isOpen && (
          <IconButton
            icon={<CloseIcon />}
            aria-label="Clear recipient"
            size="sm"
            variant="ghost"
            onClick={onClose}
          />
        )}
      </HStack>

      {/* Middle Message Section */}
      <Flex
        flex="1"
        w="full"
        px={6}
        overflowY="auto"
        direction="column"
        bg="#e5ddd5"
        // ref={contentRef}
      >
        {/*{currentMessages.map((message, index) => (*/}
        {messages[property_idInt] && messages[property_idInt][recipient] && messages[property_idInt][recipient]?.map((message, index) => (
          <Box
            ref={index === messages[property_idInt][recipient].length - 1 ? bottomMessageRef : null}
            key={message.id}
            my={2}
            p={3}
            maxWidth="60%"
            alignSelf={message.type === "sent" ? "flex-end" : "flex-start"}
            bg={message.type === "sent" ? "green.200" : "#ffffff"}
            borderRadius="md"
          >
            <Text>{message.content}</Text>
            <Text mt={1} fontSize="xs" color="gray" textAlign="right">
              {message.timestamp}
            </Text>
            {message.error && (
              <Text color="red.500" fontSize="sm">
                Failed to send
              </Text>
            )}
          </Box>
        ))}
      </Flex>

      {/* Bottom Footer Section */}

      <HStack
        position="sticky"
        bottom="env(safe-area-inset-bottom)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        py={2}
        px={6}
        bg="gray.100"
        boxShadow="sm"
      >
        <IconButton
          icon={<AddIcon />}
          aria-label="Add"
          variant="ghost"
          size="sm"
          onClick={() => setShowMenu(!showMenu)} // Toggle menu visibility
        />
        {showMenu && (
          <VStack>
            <Button onClick={() => handleModeChange("nice")}>Nice</Button>
            <Button onClick={() => handleModeChange("fun")}>Fun</Button>
            <Button onClick={() => handleModeChange("professional")}>
              Professional
            </Button>
          </VStack>
        )}
        <Textarea
          size="sm"
          resize="none"
          minH="unset"
          // overflow="hidden"
          ref={textareaRef}
          value={inputText}
          variant="filled"
          flex="1"
          borderRadius="md"
          placeholder="Type your message..."
          bg="white"
          // value={newMessage}
          onChange={handleChange} // Use handleChange here
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <IconButton
          icon={<AttachmentIcon />}
          aria-label="Attach"
          variant="ghost"
          size="sm"
        />
      </HStack>
    </VStack>
  );
};

export default NewChatPage;
