import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles.css';  // Assuming you have a styles.css file with global styles



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
