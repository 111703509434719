// authService.ts
import axios from "axios";
// import { useNavigate } from 'react-router-dom';

export const getNewAccessToken = async (): Promise<string> => {
  try {
    const response = await axios.post("/refresh-token"); // Endpoint to get a new access token
    return response.data.accessToken;
  } catch (error) {
    // const navigate = useNavigate();
    // navigate('/login');
    throw new Error("Failed to refresh token");
  }
};
