import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setToken } from "./tokenService";
import axiosInstance from "./axiosConfig";

type AuthContextType = {
  isAuthenticated: boolean;
  accessToken: string;
  login: (accessToken: string, refreshToken: string) => void;
  logout: () => void;
};

export const AuthContext = createContext<AuthContextType>(null!);

type AuthProviderProps = {
  children: ReactNode;
  // onNavigate: (path: string) => void;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  axios.defaults.withCredentials = true;
  const [accessToken, setAccessToken] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const failCounter: string | null = localStorage.getItem("authFailCounter");
  const checkAuth = async () => {
    let failCounterInt: number = 0;
    try {
      // In local storage counter

      // this url logs user in simulates the renrate successful auth
      // const response = await axios.get(process.env.REACT_APP_BACKEND_DOMAIN_URL+'/auth/login');
      // console.log(response);
      // this url needs to be tested with renrate reroute system
      // const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN_URL+'/auth/verify-token');
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_DOMAIN_URL + "/auth/verify-token",
        {},
        {
          withCredentials: true, // Include this option to send cookies
        }
      );

      if (response?.data?.valid) {
        setAccessToken(response.data.accessToken);
        setIsAuthenticated(true);
        // console.log('accessToken set to header');
        // console.log(response.data.accessToken);
        setToken(response.data.accessToken); // Store the token in memory
        // if succesful clear local storage counter
        // axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
      } else {
        setIsAuthenticated(false);
        console.log("route to renrate");
        console.log(response.data.redirect);

        if (failCounter) {
          //Checks fail counter, this is to fix infinite loop
          failCounterInt = parseInt(failCounter);
          failCounterInt++;

          if (failCounterInt >= 2) {
            //Checks of counter is more than two, then it deauths user and redirects
            await axiosInstance.get(
              process.env.REACT_APP_BACKEND_DOMAIN_URL + "/auth/logout"
            );
            localStorage.removeItem("authFailCounter");
            window.location.replace(
              process.env.REACT_APP_LOGIN_URL + "/logout"
            ); //Redirect URL
            return;
          }
        } else {
          localStorage.setItem("authFailCounter", "1");
        }

        if (response.data.redirect) {
          // If local counter is 2 or higher then don't redirect give message to user to contact admin
          let strBackendUrlClean =
            process.env.REACT_APP_BACKEND_DOMAIN_URL || "";
          strBackendUrlClean = strBackendUrlClean.replace("https://", "");
          // console.log(process.env.REACT_APP_LOGIN_URL+'/login/redirect='+strBackendUrlClean);
          window.location.href =
            process.env.REACT_APP_LOGIN_URL +
            "/login/redirect=" +
            strBackendUrlClean;
        }
      }
    } catch (error) {
      console.log(`Error refreshToken front end: ${error}`);
      console.log("error route to renrate");
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []); // Empty dependency array to run once on component mount

  const login = (newAccessToken: string, refreshToken: string) => {
    setAccessToken(newAccessToken);
    setIsAuthenticated(true);
    setToken(newAccessToken); // Store the token in memory
    // Store refreshToken securely if necessary
  };

  const logout = () => {
    setAccessToken("");
    setIsAuthenticated(false);
    // Clear the refreshToken storage here
    // navigate('/login');
    // window.location.href = process.env.REACT_APP_LOGIN_URL || 'default-url'; // Redirect to external URL
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, accessToken, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
