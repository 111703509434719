import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
// import axios from 'axios';
import { AuthContext } from "./AuthContext"; // Adjust the import path as necessary
import axiosInstance from "./axiosConfig";
import { PropertyClientContext } from "./PropertyClientContext";
import {useNavigate, useParams} from "react-router-dom";

// Define the shape of your context state
interface ChatContextState {
  chatters: { [property_id: number]: Chatter[] };
  isLoading: boolean;
  updateChatters: (newChatters: { [property_id: number]: Chatter[] }) => void;
  // Add other state items as needed
}

// Initialize the context with a default state
export const ChatContext = createContext<ChatContextState | null>(null);

// Define the type for your Chatter, if not already defined
interface Chatter {
  userId: number;
  name: string;
  avatar: string;
  lastMessage: string;
  date: string;
  property_id: number;
  unit_number:string;
  phone_number_formatted:string;
  full_name:string;
  customer_type:string;
}


// Define a type for the provider props
interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  // const [chatters, setChatters] = useState<Chatter[]>([]);
  // const { property_idParam } = useParams<{ property_idParam: string }>();
  const [chatters, setChatters] = useState<{ [property_id: number]: Chatter[] }>({});

  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  // Use AuthContext to access the accessToken
  const { accessToken } = useContext(AuthContext);
  const propertyClientContext = useContext(PropertyClientContext);
  const { propertyClients = [], updatePropertyList, currentProperty, setCurrentProperty } = propertyClientContext || {}; // Using optional chaining or a default object


  // const updateChatters = (newChatters: Chatter[]) => {
  //   setChatters(newChatters);
  // };
  const updateChatters = (newChatters: { [property_id: number]: Chatter[] }) => {
    setChatters(newChatters);
  };

  useEffect(() => {
    // Function to load chatters from the backend
    const loadChatters = async () => {
      try {
        console.log('chatcontext');
        console.log(accessToken);
        console.log(chatters);

        if(accessToken && currentProperty && chatters && chatters[currentProperty.property_id]){
          // skip since we prev loaded this properties chatters
        } else if (accessToken && currentProperty) {
          console.log(currentProperty);
          setIsLoading(true); // Start loading

          const response = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_DOMAIN_URL +
              "/api/get-property-com-log",
            { property_id: currentProperty.property_id }
          );

          console.log(response.data);
          // const chattersObj = response.data.reduce((obj: { [property_id: number]: Chatter }, item: Chatter) => {
          //   obj[item.property_id] = item;
          //   return obj;
          // }, {});
          // setChatters(chattersObj);
          const fetchedChatters = response.data; // Assuming this is an array of Chatter objects
          // Define the accumulator type for the reduce function
          type ChattersAccumulator = { [property_id: number]: Chatter[] };

          // Convert to the required format
          const newChattersObj = fetchedChatters.reduce((obj: ChattersAccumulator, chatter: Chatter) => {
            const propertyId = chatter.property_id;
            if (!obj[propertyId]) {
              obj[propertyId] = [];
            }
            obj[propertyId].push(chatter);
            return obj;
          }, {});

          // setChatters(chattersObj);
          setChatters(existingChatters => ({
            ...existingChatters,
            ...newChattersObj
          }));

          setIsLoading(false); // End loading
        } else {
          // navigate("/");
          console.log("Authorization Bearer token is not set or property not selected.");
          // Handle the scenario when the Bearer token is not set...
        }
      } catch (error) {
        console.error("Error fetching chatters:", error);
        if(currentProperty){
          const chatters = {
            [currentProperty.property_id]: [
            {
              userId: 1,
              name: "Martha Craig",
              avatar: "path_to_avatar_1.jpg",
              lastMessage: "What kind of strategy is better?",
              date: "10/28/23",
              property_id:currentProperty.property_id,
            },
            {
              userId: 2,
              name: "John Main",
              avatar: "path_to_avatar_1.jpg",
              lastMessage: "Photo",
              date: "10/27/23",
              property_id:currentProperty.property_id,
            },
            {
              userId: 3,
              name: "Mary Lopez",
              avatar: "path_to_avatar_1.jpg",
              lastMessage: "Looking for 2 Bedroom",
              date: "10/27/23",
              property_id:currentProperty.property_id,
            },
            {
              userId: 4,
              name: "Felicia Gomez",
              avatar: "path_to_avatar_1.jpg",
              lastMessage: "Thanks",
              date: "10/27/23",
              property_id:currentProperty.property_id,
            },
            {
              userId: 5,
              name: "Larry Page",
              avatar: "path_to_avatar_1.jpg",
              lastMessage: "Okay",
              date: "10/27/23",
              property_id:currentProperty.property_id,
            },
            {
              userId: 6,
              name: "Alex Beatris",
              avatar: "path_to_avatar_1.jpg",
              lastMessage: "what are your hours",
              date: "10/27/23",
              property_id:currentProperty.property_id,
            },
            {
              userId: 7,
              name: "Berry Canalis",
              avatar: "path_to_avatar_1.jpg",
              lastMessage: "I am outside",
              date: "10/27/23",
              property_id:currentProperty.property_id,
            },
              // ... add more users with respective avatars, messages, and dates
          ]
          };
        }

        setChatters(chatters);
        setIsLoading(false); // End loading
      }
    };

    loadChatters();

    // Set up Socket.IO client
    //     const socket = io('your_socket_io_server_endpoint');
    //     socket.on('newChatter', (newChatter: Chatter) => {
    //       setChatters(prevChatters => [...prevChatters, newChatter]);
    //     });
    //
    //     return () => {
    //       socket.disconnect();
    //     };
  }, [currentProperty]);

  return (
    <ChatContext.Provider value={{ chatters, updateChatters, isLoading }}>
      {children}
    </ChatContext.Provider>
  );
};
