import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Spacer,
  VStack,
  Tab,
  Tabs,
  Text,
  Icon,
} from "@chakra-ui/react";
import { ChatIcon, PhoneIcon, SettingsIcon } from "@chakra-ui/icons";
import { FaUserFriends } from "react-icons/fa";

interface MainFooterProps {
  index: number;
}

export const MainFooter = (props: MainFooterProps) => {
  const navigate = useNavigate();
  const { property_id } = useParams<{ property_id: string }>();

  useEffect(() => {}, []);

  return (
    <Box
      position="sticky"
      bottom="env(safe-area-inset-bottom)"
      zIndex={1} // Ensure it's above other content
      w="full"
      p={4}
      bg="gray.100"
      justifyContent="space-around"
      borderColor="gray.400"
      boxShadow="base"
    >
      <Tabs size="sm" variant={"line"} index={props.index} colorScheme="green">
        <Flex>
          <Tab onClick={(e) => navigate("/calls/" + property_id)}>
            <VStack spacing={2} align="center">
              <Icon as={PhoneIcon} w={6} h={6} />
              <Text fontSize="xs">Calls</Text>
            </VStack>
          </Tab>
          <Spacer />
          <Tab onClick={(e) => navigate("/mainpage/" + property_id)}>
            <VStack spacing={2} align="center">
              <Icon as={ChatIcon} w={6} h={6} />
              <Text fontSize="xs">Chats</Text>
            </VStack>
          </Tab>
          <Spacer />
          <Tab onClick={(e) => navigate("/contacts/" + property_id)}>
            <VStack spacing={2} align="center">
              <Icon as={FaUserFriends} w={6} h={6} />
              <Text fontSize="xs">Contacts</Text>
            </VStack>
          </Tab>
          <Spacer />
          <Tab onClick={(e) => navigate("/settings/" + property_id)}>
            <VStack spacing={2} align="center">
              <Icon as={SettingsIcon} w={6} h={6} />
              <Text fontSize="xs">Settings</Text>
            </VStack>
          </Tab>
        </Flex>
      </Tabs>
    </Box>
  );
};

export default MainFooter;
