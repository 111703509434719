import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChatContext } from "./ChatContext"; // Import the ChatContext
import { MessagesContext } from "./MessagesContext"; // Ensure this is correctly imported
import { PropertyClientContext } from "./PropertyClientContext"; // Ensure this is correctly imported
import MainFooter from "./MainFooter";
import {
  Box,
  VStack,
  HStack,
  Button,
  Avatar,
  Text,
  Heading,
  Icon,
  Spacer,
} from "@chakra-ui/react";
import {
  ChevronRightIcon,
  EditIcon,
  QuestionOutlineIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { Center, Spinner } from "@chakra-ui/react";

interface Chatter {
  userId: number;
  name: string;
  avatar: string;
  lastMessage: string;
  date: string;
  property_id: number;
  unit_number:string;
  phone_number_formatted:string;
  full_name:string;
  customer_type:string;
}

interface MainPageProps {
  onStartChat: (chatter: Chatter) => void;
  currentChatter: Chatter | null;
  onEndChat: () => void;
}

const MainPage: React.FC<MainPageProps> = ({
  onStartChat,
  currentChatter,
  onEndChat,
}) => {
  const navigate = useNavigate();

  const { property_id } = useParams<{ property_id: string }>();
  const chatContext = useContext(ChatContext);
  const { chatters = [], updateChatters, isLoading } = chatContext || {}; // Using optional chaining or a default object

  const messagesContext = useContext(MessagesContext);
  const { latestMessageInfo } = messagesContext || {};

  const propertyClientContext = useContext(PropertyClientContext);
  const {
    propertyClients = [],
    updatePropertyList,
    currentProperty,
    setCurrentProperty,
  } = propertyClientContext || {}; // Using optional chaining or a default object

  const openNewChatPage = () => {
    if (property_id) {
      navigate(`/mainpage/${property_id}/new-chat/`); // Replace '/new-chat' with the correct route path for NewChatPage
    }
  };

  useEffect(() => {
    console.log(property_id);
    // console.log(propertyClients);

    if (property_id) {
      const property_idInt = parseInt(property_id, 10); // 10 is the radix
      if (property_idInt) {
        const selectedProperty = propertyClients.find(
          (client) => client.property_id === property_idInt
        );
        if (setCurrentProperty && selectedProperty) {
          setCurrentProperty(selectedProperty);
        }
      }
    }
  }, [propertyClients]);

  if (!chatContext) {
    return <Box>Loading context...</Box>; // Adjust as needed
  }

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner
          size="xl"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
        />
      </Center>
    );
  }

  if (currentProperty && chatters && chatters[currentProperty.property_id]) {
    // console.log(chatters);
    chatters[currentProperty.property_id].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }


  const getInitials = (name: string): string => {
    const matches = name.match(/\b\w/g) || []; // This will be an array of matches
    const initials = ((matches.shift() || '') + (matches.pop() || '')).toUpperCase();
    return initials;
  };

  return (
    <VStack spacing={0} h="100vh" w="100%" bg="gray.100">
      {/* Header */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="gray.100"
      >
        <Button onClick={(e) => navigate("/")}>
          <ChevronLeftIcon boxSize={8} color="gray.500" />
        </Button>
        <Heading size="md">Chats</Heading>
        <Spacer />
        <Button size="sm" onClick={openNewChatPage}>
          <EditIcon boxSize={6} />
        </Button>
        <Button p={2} onClick={(e) => navigate("/help")}>
          <QuestionOutlineIcon boxSize={6} />
        </Button>
      </HStack>

      <Text
        fontSize="sm"
        fontWeight="bold"
        alignSelf="center"
        color="gray.500"
        marginBottom={"10px"}
        marginTop={"10px"}
      >
        {currentProperty?.property_name
          ? currentProperty?.property_name +
            " | " +
            currentProperty?.phoneNumber
          : "All Properties"}
      </Text>

      {/* Chats */}
      <VStack w="100%" flex="1" overflowY="auto" spacing={1} my={1}>
        {currentProperty &&
          chatters[currentProperty.property_id]?.map((chatter) => (
            <HStack
            key={chatter.userId}
            w="100%"
            p={4}
            spacing={4}
            borderBottom="1px"
            borderColor="gray.200"
            bg="white"
            _hover={{ bg: "gray.100" }} // Add a darker background on hover
            onClick={() => {
              onStartChat(chatter);
              navigate(`/mainpage/${chatter.property_id}/chat/${chatter.userId}`);
            }}
          >
            {/*<Avatar src={chatter.avatar} name={chatter.unit_number} />*/}
            {/*  <Box*/}
            {/*      width="40px"*/}
            {/*      height="40px"*/}
            {/*      borderRadius="full"*/}
            {/*      bg="gray.200"*/}
            {/*      display="flex"*/}
            {/*      alignItems="center"*/}
            {/*      justifyContent="center"*/}
            {/*  >*/}
            {/*    <Text fontSize="md" fontWeight="bold">*/}
            {/*      {chatter.unit_number || getInitials(chatter.full_name)}*/}
            {/*    </Text>*/}
            {/*  </Box>*/}
            {/*<VStack align="start" spacing={1}>*/}
            {/*  <Text fontWeight="bold">{chatter.name}<span>{" "+chatter.customer_type}</span></Text>*/}
            {/*  <Text fontSize="sm">{chatter.lastMessage}</Text>*/}
            {/*</VStack>*/}
              <Box
                  display="flex"
                  alignItems="center"
                  p={4}
                  // borderBottom="1px solid"
                  // borderColor="gray.200"
                  // bg="white"
              >
                <Box
                    width="40px"
                    height="40px"
                    borderRadius="full"
                    bg="gray.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginRight="12px"
                >
                  <Text fontSize="md" fontWeight="bold">
                    {chatter.unit_number || getInitials(chatter.full_name)}
                  </Text>
                </Box>
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold" fontSize="lg">
                    {chatter.full_name}<span style={{ fontWeight: 'normal' }}> {" - " + chatter.customer_type}</span>
                  </Text>
                  <Text color="gray.500" fontSize="sm">{chatter.phone_number_formatted}</Text>
                  <Text fontSize="sm">{chatter.lastMessage}</Text>
                </VStack>
              </Box>
            <Spacer />
              <Box flex="1" textAlign="right">
                <Text fontSize="sm" color="gray.500">
                  {chatter.date}
                </Text>
                <Icon as={ChevronRightIcon} w={5} h={5} color="gray.500" />
              </Box>
            </HStack>
          ))}
      </VStack>

      {/* Bottom Navigation */}
      <MainFooter index={1} />
    </VStack>
  );
};

export default MainPage;
