import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import MainFooter from "./MainFooter";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  QuestionOutlineIcon,
  Search2Icon,
} from "@chakra-ui/icons";
import { PropertyClientContext } from "./PropertyClientContext";

interface Call {
  id: number;
  customer_phone: string;
  system_phone: string;
  is_incoming: boolean;
  is_outgoing: boolean;
  is_missed_call: boolean;
  customer_caller_id: string;
  date: string;
}

export const CallsPage = () => {
  // const propertyClients = useContext(PropertyClientContext);
  const navigate = useNavigate();
  const { property_id } = useParams<{ property_id: string }>();
  const [callFilter, setCallFilter] = useState("all"); //all, missed
  const [callsCopy, setCallsCopy] = useState<Call[]>([]); //Temp store when sorting/filtering calls
  const [calls, setCalls] = useState<Call[]>([
    {
      id: 1,
      customer_phone: "(915) 304-6628",
      system_phone: "",
      is_incoming: true,
      is_outgoing: false,
      is_missed_call: true,
      customer_caller_id: "EL PASO      TX",
      date: "12/5/2023 4:31PM",
    },
    {
      id: 2,
      customer_phone: "(915) 304-6628",
      system_phone: "",
      is_incoming: true,
      is_outgoing: false,
      is_missed_call: true,
      customer_caller_id: "EL PASO      TX",
      date: "12/5/2023 4:32PM",
    },
    {
      id: 3,
      customer_phone: "(915) 304-6628",
      system_phone: "",
      is_incoming: true,
      is_outgoing: false,
      is_missed_call: false,
      customer_caller_id: "EL PASO      TX",
      date: "12/5/2023 4:33PM",
    },
  ]);

  useEffect(() => {
    function handleCallFilter() {
      switch (callFilter) {
        case "all":
          if (callsCopy.length > 0) {
            setCalls(callsCopy);
          }

          break;
        case "missed":
          setCallsCopy(calls);
          let filteredCalls = calls.filter((x) => x.is_missed_call === true);
          setCalls(filteredCalls);
          break;
      }
    }
    handleCallFilter();
  }, [callFilter]);

  useEffect(() => {
    async function fetchRecentCalls() {
      try {
        //todo route to fetch recent calls here
      } catch (err) {}
    }
    fetchRecentCalls();
  }, []);

  return (
    <VStack spacing={0} h="100vh" w="100%" bg="gray.100">
      {/* Header */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="gray.100"
      >
        <Button onClick={(e) => navigate("/")}>
          <ChevronLeftIcon boxSize={8} color="gray.500" />
        </Button>
        <Heading size="md">Calls</Heading>
        <Spacer />
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input bg={"white"} placeholder="Search" />
        </InputGroup>
        <Button p={2} onClick={(e) => navigate("/help")}>
          <QuestionOutlineIcon boxSize={6} />
        </Button>
      </HStack>
      <Text
        fontSize="sm"
        fontWeight="bold"
        alignSelf="center"
        color="gray.500"
        marginBottom={"10px"}
        marginTop={"10px"}
      >
        {/*{propertyClients?.propertyClient?.phoneNumber*/}
        {/*  ? propertyClients?.propertyClient?.property_name +*/}
        {/*    " | " +*/}
        {/*    propertyClients?.propertyClient?.phoneNumber*/}
        {/*  : "All Properties"}*/}
      </Text>

      <Flex>
        <Box
          as={"button"}
          bg={callFilter === "all" ? "white" : ""}
          p={2}
          px={4}
          borderRadius={"10px"}
          onClick={(e) => setCallFilter("all")}
        >
          <Text>All</Text>
        </Box>
        <Box
          as={"button"}
          bg={callFilter === "missed" ? "white" : ""}
          p={2}
          px={4}
          borderRadius={"10px"}
          onClick={(e) => setCallFilter("missed")}
        >
          <Text>Missed</Text>
        </Box>
      </Flex>

      {/* Calls */}
      <VStack w="100%" flex="1" overflowY="auto" spacing={1} my={1}>
        {calls.map((call) => (
          <HStack
            key={call.id}
            w="100%"
            p={4}
            spacing={2}
            borderBottom="1px"
            borderColor="gray.200"
            bg="white"
            _hover={{ bg: "gray.100" }} // Add a darker background on hover
          >
            <Box>
              <Text
                fontWeight="bold"
                color={call.is_missed_call ? "red" : "black"}
              >
                {call.customer_phone}
              </Text>
              <Text>{call.customer_caller_id}</Text>
            </Box>
            <Box flex="1" textAlign="right">
              <Text fontSize="sm" color="gray.500">
                {call.date}
              </Text>
            </Box>
            <Icon as={ChevronDownIcon} w={5} h={5} color="gray.500" />
          </HStack>
        ))}
      </VStack>
      <MainFooter index={0} />
    </VStack>
  );
};

export default CallsPage;
