import React, { lazy, Suspense, useContext, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import MainFooter from "./MainFooter";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Search2Icon,
  QuestionOutlineIcon,
} from "@chakra-ui/icons";
import { PropertyClientContext } from "./PropertyClientContext";
const CallModal = lazy(() => import("./CallModal"));

interface Contact {
  id: number;
  avatar: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  contact_type: string;
}

export const ContactsPage = () => {
  const propertyClient = useContext(PropertyClientContext);
  const navigate = useNavigate();
  const { property_id } = useParams<{ property_id: string }>();
  const [contacts, setContacts] = useState<Contact[]>([
    {
      id: 1,
      avatar: "path_to_avatar_1.jpg",
      first_name: "David",
      middle_name: "",
      last_name: "Morales",
      email: "david.morales@innovativemanage.com",
      phone_number: "(915) 304-6628",
      contact_type: "resident",
    },
    {
      id: 2,
      avatar: "path_to_avatar_1.jpg",
      first_name: "Abel",
      middle_name: "",
      last_name: "Legaspy",
      email: "abel@innovativemanage.com",
      phone_number: "(915) 304-6628",
      contact_type: "owner",
    },
    {
      id: 3,
      avatar: "path_to_avatar_1.jpg",
      first_name: "Tony",
      middle_name: "",
      last_name: "Legaspy",
      email: "tony@innovativemanage.com",
      phone_number: "(915) 304-6628",
      contact_type: "manager",
    },
  ]);

  return (
    <VStack alignItems={"left"} spacing={0} h="100vh" w="100%" bg="gray.100">
      {/* Header */}
      <HStack
        position="sticky"
        top="env(safe-area-inset-top)"
        zIndex={1} // Ensure it's above other content
        w="100%"
        p={4}
        boxShadow="base"
        bg="gray.100"
      >
        <Button onClick={(e) => navigate("/")}>
          <ChevronLeftIcon boxSize={8} color="gray.500" />
        </Button>
        <Heading size="md">Contacts</Heading>
        <Spacer />
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input bg={"white"} placeholder="Search" />
        </InputGroup>
        <Button p={2} onClick={(e) => navigate("/help")}>
          <QuestionOutlineIcon boxSize={6} />
        </Button>
      </HStack>
      <Text
        fontSize="sm"
        fontWeight="bold"
        alignSelf="center"
        color="gray.500"
        marginBottom={"10px"}
        marginTop={"10px"}
      >
        {/*{propertyClient?.propertyClient?.phoneNumber*/}
        {/*  ? propertyClient?.propertyClient?.property_name +*/}
        {/*    " | " +*/}
        {/*    propertyClient?.propertyClient?.phoneNumber*/}
        {/*  : "All Properties"}*/}
      </Text>
      <Box>
        <Button bg={"white"} my={4} mx={2}>
          + Add Contact
        </Button>
      </Box>
      {/* Contacts */}

      <VStack w="100%" flex="1" overflowY="auto" spacing={1} my={1}>
        {contacts.map((contact) => (
          <HStack
            className={""}
            key={contact.id}
            w="100%"
            p={4}
            spacing={4}
            borderBottom="1px"
            borderColor="gray.200"
            bg="white"
            _hover={{ bg: "gray.100" }} // Add a darker background on hover
          >
            <Avatar
              src={contact.avatar}
              name={contact.first_name + " " + contact.last_name}
            />
            <VStack align="start" spacing={1}>
              <Text fontWeight="bold">
                {contact.first_name} {contact.last_name}
              </Text>
              <Text>{contact.contact_type}</Text>
            </VStack>
            <Spacer />
            <Icon as={ChevronRightIcon} w={5} h={5} color="gray.500" />
          </HStack>
        ))}
      </VStack>
      <MainFooter index={2} />
    </VStack>
  );
};

export default ContactsPage;
