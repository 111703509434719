// sockSingleton.tsx
import io, { Socket } from 'socket.io-client';

const socketUrl = process.env.REACT_APP_WEBSOCKETS_URL;

if (!socketUrl) {
    throw new Error('REACT_APP_WEBSOCKETS_URL environment variable is not set.');
}

let socket: Socket | null = null;

const initializeSocket = (token: string) => {
    socket = io(socketUrl, {
        query: { token }
    });

    // You can also set up event listeners here if needed
    // For example:
    socket.on('connect', () => {
      console.log('Socket connected');
    });
};

const getSocket = (): Socket => {
    if (!socket) {
        throw new Error('Socket not initialized. Call initializeSocket with a token first.');
    }
    return socket;
};

export { initializeSocket, getSocket };



// // sockSingleton.tsx
// import io from 'socket.io-client';
//
// const socketUrl = process.env.REACT_APP_WEBSOCKETS_URL;
// if (!socketUrl) {
//     throw new Error('REACT_APP_WEBSOCKETS_URL environment variable is not set.');
// }
// const socket = io(socketUrl);
// export default socket;
