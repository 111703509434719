import React, { createContext, useContext, useState, ReactNode } from "react";
// import axios from 'axios';
import axiosInstance from "./axiosConfig";

interface ChatGPTContextType {
  response: string;
  // mode: "nice" | "fun" | "professional";
  // setMode: (mode: "nice" | "fun" | "professional") => void;
  fetchGPTResponse: (prompt: string, mode: string) => Promise<void>;
  handleModeChange: (newMode: "nice" | "fun" | "professional" | "professional_spanish") => void;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  showMenu: boolean;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  inputText: string;
}

// interface ChatGPTContextType {
//     response: string;
//     fetchGPTResponse: (prompt: string) => Promise<void>;
// }

export const ChatGPTContext = createContext<ChatGPTContextType>(null!);

interface ChatGPTProviderProps {
  children: ReactNode;
}

export const ChatGPTProvider: React.FC<ChatGPTProviderProps> = ({ children }) => {
    // const [mode, setMode] = useState<'nice' | 'fun' | 'professional'>('professional');
    const [response, setResponse] = useState('');
    const [showMenu, setShowMenu] = useState(false);
    const [inputText, setInputText] = useState('');

    // const openAI = axios.create({
    //     baseURL: 'https://api.openai.com/v1/',
    //     headers: {
    //         'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    //         'Content-Type': 'application/json',
    //     },
    // });

    const handleModeChange = async (newMode: 'nice' | 'fun' | 'professional' | 'professional_spanish') => {
        // setMode(newMode);

        // Assuming 'inputText' is the current message the user has typed
        // const modifiedPrompt = `${newMode}: ${inputText}`;
        await fetchGPTResponse(inputText,newMode);

        setShowMenu(false); // Hide the menu after selection
    };


    const fetchGPTResponse = async (prompt: string, mode: string) => {

        try {
            const payload = {
                    prompt: prompt,
                    mode: mode,
            };

            const response = await axiosInstance.post(    process.env.REACT_APP_BACKEND_DOMAIN_URL+'/api/send-gpt-prompt', payload);

            if (response.data.choices && response.data.choices.length > 0) {
                // console.log('choices');
                // console.log(response.data.choices);
                // console.log(response.data.choices[0]);
                if(response.data.choices[0].message && response.data.choices[0].message.content){

                    // console.log(response.data.choices[0].message.content);
                    setResponse(response.data.choices[0].message.content);
                }

            } else {
                setResponse('No response received.');
            }
        } catch (error) {
            console.error('Error fetching GPT response:', error);
            setResponse('Error fetching response.');
        }
    };

  return (
    <ChatGPTContext.Provider
      value={{
        response,
        fetchGPTResponse,
        handleModeChange,
        setShowMenu,
        showMenu,
        inputText,
        setInputText,
      }}
    >
      {children}
    </ChatGPTContext.Provider>
  );
};
