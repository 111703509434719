import React, {createContext, useState, useEffect, ReactNode, useContext} from "react";
import { AuthContext } from "./AuthContext";
import axiosInstance from "./axiosConfig"; // Adjust the import path as necessary


// Define the shape of your context state
interface PropertyClientState {
  propertyClients: PropertyClient[];
  isLoading: boolean; // Ensure this is included
  updatePropertyList: (propertyClients: PropertyClient[]) => void;
  currentProperty: PropertyClient | null;
  setCurrentProperty?: (property: PropertyClient | null) => void; // Ensure this is included if you need it
  // Add other state items as needed
}

// Initialize the context with a default state
export const PropertyClientContext = createContext<PropertyClientState | null>(null);

// Define the type for your Chatter, if not already defined
interface PropertyClient {
  property_id: number;
  property_name: string;
  phoneNumber: string;
  property_address: string;
}

// Define a type for the provider props
interface PropertyClientProviderProps {
  children: ReactNode;
}



export const PropertyClientProvider: React.FC<PropertyClientProviderProps> = ({
  children,
}) => {

  const [propertyClients, setPropertyClient] = useState<PropertyClient[]>([]);

  const [currentProperty, setCurrentProperty] = useState<PropertyClient | null>(null);

  const [isLoading, setIsLoading] = useState(true); // Add a loading state.

  const { accessToken } = useContext(AuthContext);

  const updatePropertyList = (newPropertyClients: PropertyClient[]) => {
    setPropertyClient(newPropertyClients);
  };


  useEffect(() => {
    // Function to load chatters from the backend
    const loadProperties = async () => {
      try {
        // console.log(accessToken);
        if (accessToken) {
          setIsLoading(true); // Start loading

          const response = await axiosInstance.post(
              process.env.REACT_APP_BACKEND_DOMAIN_URL +
              "/api/get-auth-properties"
          );

          console.log(response.data);
          updatePropertyList(response.data);
          setIsLoading(false); // End loading
        } else {
          console.log("Authorization Bearer token is not set.");
          // Handle the scenario when the Bearer token is not set...
        }
      } catch (error) {
        console.error("Error fetching chatters:", error);
        const propertyClients: PropertyClient[] = [
          {
            property_id: 1008,
            property_name: "THE501",
            phoneNumber: "(915) 555-5555",
            property_address: "",
          },
          {
            property_id: 199,
            property_name: "Test Property",
            phoneNumber: "(915) 444-4444",
            property_address: "",
          },
          // ... add more users with respective avatars, messages, and dates
        ];
        updatePropertyList(propertyClients);
        setIsLoading(false); // End loading
      }
    };

    loadProperties();


  }, [accessToken]);

  return (
    <PropertyClientContext.Provider
      value={{ propertyClients, updatePropertyList, isLoading, currentProperty, setCurrentProperty }}
    >
      {children}
    </PropertyClientContext.Provider>
  );
};
